<template>
  <div>
    <page-title title="Create Ticket" :breadcrumbs-items="breadcrumbsItems"></page-title>

    <!-- <v-row class="mb-1">
      <v-col>
        <v-btn color="primary" small link :to="{ name: 'ComplaintTicketCreate' }"><v-icon class="mr-2">mdi-pen</v-icon> Buat Tiket</v-btn>
      </v-col>
    </v-row>-->

    <v-row class="mb-4">
      <v-col>
        <v-btn color class="mr-4" @click="goBack()">Back</v-btn>
        <v-btn color="error" class="mr-4" @click="reset">Reset</v-btn>
        <v-btn :disabled="!valid" color="success" class="mr-4" @click="saveTicket">Submit</v-btn>
      </v-col>
    </v-row>

    <v-form ref="form" v-model="valid" lazy-validation>
      <v-row>
        <v-col cols="12" lg="8">
          <v-card outlined>
            <v-card-title>Craete new Ticket</v-card-title>
            <v-card-text>
              <v-text-field dense class="mb-2" outlined v-model="subjects" :rules="subjectsRules" label="Subjects" required></v-text-field>
              <vue-editor outlined v-model="body" class="mb-6" />
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="12" lg="4">
          <v-card class="mb-4" outlined>
            <v-card-text>
              <v-select v-model="priority" :items="priorityItems" :rules="[v => !!v || 'Priority is required']" label="Priority" required outlined class="mb-2" dense></v-select>
              <v-select v-model="source" :items="sourceItems" :rules="[v => !!v || 'Source is required']" label="Source" required outlined class="mb-2" dense></v-select>

              <v-text-field class="mb-2" outlined v-model="no_" label="Source No." dense></v-text-field>
            </v-card-text>
          </v-card>
          <v-card class="mb-4" outlined>
            <v-toolbar dense elevation="0">
              <v-toolbar-title>Attachments</v-toolbar-title>
              <v-spacer></v-spacer>
              <v-btn icon @click="uploadAttachment()">
                <v-icon>mdi-plus</v-icon>
              </v-btn>
            </v-toolbar>
            <v-card-text class="py-5" v-if="attachmentItems.length == 0">
              <p>Add new attachment</p>
            </v-card-text>
            <v-list v-if="attachmentItems.length > 0">
              <v-list-item link v-for="(a, i) in attachmentItems" :key="i">
                <v-list-item-content>
                  <v-list-item-title :title="a.file_name">{{ a.file_name }}</v-list-item-title>
                </v-list-item-content>
                <v-spacer></v-spacer>
                <v-btn icon link @click="goToExternalUrl(a.file_src)">
                  <v-icon>mdi-download</v-icon>
                </v-btn>
                <v-btn icon @click="deleteAttachment(i)">
                  <v-icon>mdi-delete</v-icon>
                </v-btn>
              </v-list-item>
            </v-list>
            <v-card-text>
              <p class="font-italic text--disabled">
                Allowed File Extensions:
                <br />.jpg, .jpeg, .gif, .png, .zip, .rar, .gz, .7z, .txt, .xls, .xlsx, .doc, .docx, .pdf, .htm, .html, .csv, .sql (Max file size: 2MB)
              </p>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-form>
    
    <v-row>
      <v-col class="d-flex" cols="12">
        <v-btn color class="mr-4" @click="goBack()">Back</v-btn>
        <v-btn color="error" class="mr-4" @click="reset">Reset</v-btn>
        <v-btn :disabled="!valid" color="success" class="mr-4" @click="saveTicket">Submit</v-btn>
      </v-col>
    </v-row>

    <input type="file" class="d-none" name="fileupload" id="fileupload" ref="reffileupload" @change="handleFileUpload" />
  </div>
</template>

<script>
import { VueEditor } from 'vue2-editor'

export default {
  components: {
    VueEditor,
  },
  beforeMount() {
    this.requiredLogin()
  },
  mounted() {
    this.$store.state.showBackButton = true
    this.setTitle('Create Ticket')
  },

  data: () => ({
    showLoadingComponent: false,
    body: '',
    valid: true,
    subjects: '',
    subjectsRules: [
      v => !!v || 'Subjects is required',
      v => (v && v.length >= 4) || 'Subjects must be less than 10 characters',
    ],
    priority: 'LOW',
    priorityItems: ['LOW', 'NORMAL', 'MEDIUM', 'HIGH'],
    source: '-',
    sourceItems: ['-', 'ORDER', 'DELIVERY', 'INVOICE', 'PAYMENT'],
    no_: '-',

    attachmentItems: [],

    breadcrumbsItems: [
      {
        text: 'Dashboard',
        disabled: false,
        href: '/',
      },
      {
        text: 'Complaint',
        disabled: false,
        href: '/complaint',
      },
      {
        text: 'Create Ticket',
        disabled: true,
        href: '#',
      },
    ],
  }),

  methods: {
    validate() {
      this.$refs.form.validate()
    },

    reset() {
      this.$refs.form.reset()
    },

    resetValidation() {
      this.$refs.form.resetValidation()
    },

    async saveTicket() {
      if (this.$refs.form.validate() == true) {
        this.showLoadingOverlay(true)

        var formData = new FormData()
        formData.append('priority', this.priority)
        formData.append('subjects', this.subjects)
        formData.append('body', this.body)
        formData.append('source', this.source)
        formData.append('no_', this.no_)
        formData.append('attachments', JSON.stringify(this.attachmentItems))

        await this.$axios
          .post('ticket/save', formData)
          .then(res => {
            // console.log(res.data.data);
            this.showLoadingOverlay(false)
            this.redirect('ComplaintTicketDetail', { ticket_no: res.data.data.ticket_no})
          })
          .catch(error => {
            if (error.response) {
              // The request was made and the server responded with a status code
              // that falls out of the range of 2xx
              // console.log(error.response.data)
              // console.log(error.response.status)
              // console.log(error.response.headers)
            } else if (error.request) {
              // The request was made but no response was received
              // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
              // http.ClientRequest in node.js
              // console.log(error.request)
            } else {
              // Something happened in setting up the request that triggered an Error
              // console.log('Error', error.message)
            }
            // console.log(error.config)
            this.$awn.alert('Internal Server Error')

            this.showLoadingOverlay(false)
          })
      }
    },

    uploadAttachment(n) {
      this.$refs.reffileupload.click()
      this.n = n
    },

    handleFileUpload() {
      this.fileupload = this.$refs.reffileupload.files[0]

      // if (!confirm('Upload File?')) return

      this.showLoadingDialog(true)

      let formData = new FormData()
      formData.append('fileupload', this.fileupload)
      this.$axios
        .post('ticket/upload-attachment', formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        })
        .then(res => {
          this.showLoadingDialog(false)
          if (res.status) {
            var resData = res.data
            if (resData.status == 'success') {
              this.showAlert(resData.status, resData.message)
              this.attachmentItems.push(resData.data)
            }
          } else {
            this.showAlert('error', 'Opp.. something error.')
          }
        })
        // eslint-disable-next-line no-unused-vars
        .catch(error => {
          this.showLoadingDialog(false)
          // console.log('FAILURE!!')
        })
    },

    deleteAttachment(i) {
      if (!confirm('Delete attachment?')) return
      this.attachmentItems.splice(i, 1)
    },
  },
}
</script>


<style>
.ql-editor {
  min-height: 300px !important;
}
</style>